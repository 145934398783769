import React from "react"

import Octagon from "./octagon"
import { Grid } from "./styledcomponents"

function MMAIntegration(props) {
  return (
    <Grid
      columns="repeat(4,1fr)"
      columnsMobile="1fr"
      columnsTablet="repeat(2,1fr)"
      gridGapMobile="2em"
      gridGap="0"
    >
      {props.values.map(({ id, ...props }) => (
        <Octagon key={id} {...props} />
      ))}
    </Grid>
  )
}

export default MMAIntegration
