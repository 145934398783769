import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentAndPicture from "../components/content-and-picture"

import { HeroSimple } from "../components/hero"
import ValueBox from "../components/valuebox"
import MMAIntegration from "../components/mmaIntegration"
import Schedule from "../components/schedule"
import TrustBox from "../components/trusbox"
import IIIStep from "../components/3step"
import Testimonials from "../components/testimonials"
import { ContactForm, Download } from "../components/contactform"
import SideBlocks from "../components/side-blocks"
import LittleNinjas from "../components/littleNinjas"

import { Container, H2, H3, P } from "../components/styledcomponents"

const MMA = ({ location, data }) => {
  return (
    <Layout pathname={location.pathname}>
      <SEO
        title=" MMA / Kickboxen für Kinder, Jugendliche und Erwachsene"
        description=" Ob zur Selbstverteidigung oder um deine Fitness zu steigern - unsere SV Training machen riesig Spaß! Ob Anfänger oder Fortgeschrittener, probier es aus! "
      />
      <HeroSimple
        title="MMA & Kickboxen in München"
        values={[
          {
            id: 1,
            img: data.hero1,
            imgAlt: "Yoga in München",
          },
          {
            id: 2,
            img: data.hero2,
            imgAlt: "MMA - Mixed Martial Arts in München",
          },
          {
            id: 3,
            img: data.hero4,
            imgAlt: "MMA für Jugendliche in München",
          },
          {
            id: 4,
            img: data.hero3,
            imgAlt: "MMA für Kinder in München",
          },
          {
            id: 5,
            img: data.hero5,
            imgAlt: "BJJ für Kinder in München",
          },
        ]}
        color="#ffffff"
        buttoncolor="#f17915"
      />
      <ValueBox
        values={[
          {
            id: 1,
            value: "Stressabbau",
          },
          { id: 2, value: "Fitness" },
          { id: 3, value: "Freundschaft" },
          { id: 4, value: "Disziplin" },
        ]}
      />
      <ContentAndPicture
        h2="Was ist MMA?"
        description={
          <P>
            MMA ist die Kunst, Striking und Grappling Stile in ein effektives
            System zu integrieren. Zum Beispiel, George Saint-Pierre (mehrfacher
            UFC-Champion) ist nicht der besten (Kick)Boxer/Muay Thai kämpfer,
            ist nicht der besten Ringer oder Grappler. GSP ist der besten
            integrierer: da er gut genug in alle Disziplinen ist, konnte er von
            bessere (Kick)Boxer/Muay Thai kämpfer, Ringer und Grappler gewinnen.
            Es ist genau diese ‚integration‘, worauf wir uns während unserer
            MMA-Stunden konzentrieren. Die Techniken kommen meist aus dem Muay
            Thai, (Kick)boxen, Brazilian Jiu Jitsu (BJJ) und Ringen.
          </P>
        }
      />

      <ContentAndPicture
        h2="Wie sieht die Integration in der Praxis aus?"
        description={
          <P>
            Wir teilen MMA in vier Sub-Disziplinen. Für jeden Disziplin gibt es
            unterschiedliche Striking/Grappling/Ringer Techniken, Kombinationen,
            Übungen und Sparring-Spiele, damit Du das erlernte Schritt für
            Schritt lernst anzuwenden.
          </P>
        }
      />
      <MMAIntegration
        values={[
          {
            id: 1,
            image: data.shootBoxing.childImageSharp.gatsbyImageData,
            title: "Shoot-Boxing",
            description: "der Übergang von Striking zum Boden durch Take-downs",
          },
          {
            id: 2,
            image: data.mmaService.childImageSharp.gatsbyImageData,
            title: "Grapple-Boxing",
            description:
              "Clinchen mit Schlaegen und Knien in der mitte des Käfigs/Octagons",
          },
          {
            id: 3,
            image: data.cageWrestling.childImageSharp.gatsbyImageData,
            title: "Grapple-Boxing am Octagon",
            description: "Wie Grapple-Boxing aber am Käfig/Octagon",
          },
          {
            id: 4,
            image: data.groundAndPound.childImageSharp.gatsbyImageData,
            title: "Ground & Pound",
            description: "Kontrolle und Striking auf dem Bodem",
          },
        ]}
      />

      <ContentAndPicture
        h2="Wie läuft ein MMA Training ab?"
        list={[
          "Warming Up: Bewegungen und Übungen die sowohl Deinen Körper Stärken als auch Kampfsport Techniken unterstützen",
          "Technik Teil: je nach Thema übst Du verschiedene Striking/Grappling Techniken und Kombinationen",
          "Sparring Teil: unterschiedliche Übungskämpfe Übungen, dabei wird die Kraft aus den Techniken herausgenommen und es werden Techniken ausgetauscht. Man lernt Timing und Fokus.",
          "Muay-Thai Style Cooling Down",
        ]}
        ordered
        imgLeft={data.headkick}
      />

      <ContentAndPicture
        h2="MMA für Jugendliche und Erwachsene"
        id="jugendliche-und-erwachsene"
        description={
          <>
            <P>
              Neben dem erlernen diverse Techniken verbessert sich Deine
              körperliche und mentale Fitness sehr schnell. Gleichzeitig stellen
              wir sicher, dass Du in einer freundschaftlichen und sicheren
              Atmosphäre trainierst, wo Du Spaß hast, konditionell gefordert
              bist und lernst Dich zu verteidigen.
            </P>
            <H3>MMA Training:</H3>
          </>
        }
        list={[
          "Steigert körperliche & mentale Fitness",
          "Baut Stress ab",
          "Macht Dich gesünder und Fitter",
          "Steigert Selbstbewusstsein",
          "Macht Spaß",
        ]}
        imgRight={data.mmaYouth}
      />

      <ContentAndPicture
        h2="MMA / Kickboxen für Kinder"
        id="kinder"
        description={
          <>
            <P>
              Auch Kinder erfahren jetzt schon viel Stress im Alltägigen Leben.
              Dementsprechend brauchen sie die Chance sich, in eine sichere und
              Freundliche Umgebung, richtig auspowern und entspannen zu können.
            </P>
            <H3>MMA / Kickboxen Training:</H3>
          </>
        }
        list={[
          "Setzt Mobbing ein Ende",
          "Steigert Selbstvertrauen",
          "Steigert Körperhaltung und generelle Stabilität",
          "Fordert auf Spielerische Weise Disziplin und Konzentration",
          "Verbessert Körperliche und Geistige Kontrolle",
          "Kinder lernen wie sich Verbal – zur Not auch körperlich, verteidigen können",
        ]}
        imgLeft={data.mmaKids}
      />
      <LittleNinjas setImageRight id="ninjas" />
      <SideBlocks>
        Erreiche die Form deines Lebens | Spaß am Training | Effektiven
        Kampfsport lernen
      </SideBlocks>
      <Schedule filter="MMA" />
      <Container backgroundColor="transparent" padding="4em 0" />
      <TrustBox
        title="Unser Yoga- und Kampfsportangebot überzeugt:"
        values={[
          {
            id: 1,
            img: data.trust1,
            trust: "Kampfsportschule des Jahres 2020",
          },
          {
            id: 5,
            img: data.trust5,
            trust: "Yogalehrerausbildung in Indien von Lalit Kumar",
          },
          {
            id: 3,
            img: data.trust3,
            trust: "National und International erfolgreich",
          },
          {
            id: 6,
            img: data.trust6,
            trust: "BJJ Graduierung duch Cadu Francis",
          },
          {
            id: 4,
            img: data.trust4,
            trust: "Begeisterte Kunden",
          },
          {
            id: 2,
            img: data.trust2,
            trust: "IBJJF Zertifiziert",
          },
        ]}
      />
      <IIIStep
        title="Jede größere Änderung beginnt mit einem Schritt!"
        description={`Egal, ob Du nach der Arbeit Stress abbauen, abnehmen, Dich in Form
          bringen oder Dich körperlich oder mental herausfordern möchtest, Team
          Laurien & Alex hilft Dir dabei, Dein Ziel zu erreichen.
        `}
        pathname={location.pathname}
        values={[
          {
            id: 1,
            img: data.step1,
            value: "Schritt 1 ",
            statement: "Buche Deine kostenlose Probestunde noch heute!",
          },
          {
            id: 2,
            img: data.step2,
            value: "Schritt 2",
            statement:
              "Habe Spaß beim Training mit dem Team & freue Dich auf fortlaufende Unterstützung.",
          },
          {
            id: 3,
            img: data.step3,
            value: "Schritt 3",
            statement:
              "Erfahre wie sich Deine körperliche und geistige Fitness verbessert!",
          },
        ]}
      />
      <Container backgroundColor="transparent">
        <H2 center noLine>
          Wir versprechen Dir, Du fühlst Dich jederzeit wilkommen!
        </H2>
      </Container>
      <Testimonials
        title="Das sagen unsere Mitglieder:"
        values={[
          {
            id: 4,
            review:
              "TLA gibt es nun mehr denn 2 Jahre. Mein Sohn (8 Jahre) ist seit der ersten Stunde BJJ dabei und immer noch Feuer und Flamme für diesen Kampfsport. Er hatte sich nie für irgendein Hobby interessiert, doch gleich nach der ersten kostenlosen Probestunde statt für ihn fest, das gefällt ihm, hier will er bleiben. Mittlerweile haben sich seine Skills dank der fürsorglichen und kompetenten Hinwendung durch Laurien und Alex so stark verbessert, dass er sogar auf Turnieren im Ausland Gold gewinnt. Wir, die Eltern, sind mittlerweile auch feste Mitglieder bei TLA und trainieren regelmäßig bei MMA und BJJ mit. Somit sind Laurien und Alex ein Gewinn für unsere ganze Familie geworden und wir möchten Sie nicht mehr missen!!!",
            reviewer: "Susanne R.",
            platform: "meunchen.de",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 1,
            review:
              "Kleines, sauberes Gym mit familiärer Atmosphäre. Laurien und Alex machen ihren Job sauber und mit Herz. Sie gehen toll mit Kindern um, haben ein super Verhältnis zu den Jugendlichen und jeder wird als Freund empfangen und nicht als zahlender Kunde gesehen. Hier stimmt das Verhältnis zwischen Härte und Herz. Fachlich und technisch sind die zwei Top und ergänzen sich gut. Die Trainings sind super und am nächsten Tag weiß man, was man getan hat 👍🏻",
            reviewer: "Sebastian R.",
            platform: "google.de",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 2,
            reviewer: "Nacht Glocke",
            img:
              "https://lh3.ggpht.com/-EF1fjB92uWs/AAAAAAAAAAI/AAAAAAAAAAA/6Yz6efcUG2g/s128-c0x00000000-cc-rp-mo/photo.jpg",
            review:
              "Tolle Kampfsportschule, Laurin und Alex sind mega sympathisch und sehr professionell. Die Kampfsportstunden machen richtig Spaß und Yoga ist auch super. Vor allem die Aktion im Sommer #yogaamfasaneriesee, bei der erfolgreich Geld für die Münchner Tafel gesammelt wurde. Große Empfehlung",
            platform: "google.de",
          },
          {
            id: 3,
            reviewer: "macmel 2",
            img:
              "https://lh4.ggpht.com/-BZVPNvS1X0I/AAAAAAAAAAI/AAAAAAAAAAA/VgwMgc_iE4Q/s128-c0x00000000-cc-rp-mo/photo.jpg",
            review:
              "Ein Super Gym!!! Echt tolle Leute und super Trainer mit Erfahrung, jedes mal lernt man was neues und wird im in seinen individuellen Stärcken aber auch Scheächen gestärkt. Kann man echt nur weiter empfehlen, wenn jemand ein neues Gym mit familiärer Atmosphäre sucht!",
            platform: "google.de",
          },
        ]}
      />
      <div>
        <ContactForm />
      </div>
      <Download image={data.magnet} />
    </Layout>
  );
}
export const query = graphql`{
  hero1: file(relativePath: {eq: "MMA/Kickboxen_MMA_Boxen_Boxsack.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero2: file(relativePath: {eq: "MMA/MMA-Kickboxen-Leiter-Drills.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero3: file(relativePath: {eq: "MMA/Boxen_Kickboxen.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero4: file(relativePath: {eq: "MMA/High_Kick.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  hero5: file(relativePath: {eq: "MMA/MMA_BJJ_Ground_and_Pound.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  shootBoxing: file(
    relativePath: {eq: "MMA/Kickboxen_MMA_Laurien_Flying_Knee.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  mmaService: file(relativePath: {eq: "MMA-Muenchen-double-leg-takedown.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  cageWrestling: file(relativePath: {eq: "MMA/MMA-Jugend-double-leg.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  groundAndPound: file(relativePath: {eq: "MMA/MMA_BJJ_Ground_and_Pound.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  ablauf: file(relativePath: {eq: "MMA/Kickboxen_MMA_Boxsack.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  headkick: file(relativePath: {eq: "MMA/Highkick_Kick_Headkick.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  mmaYouth: file(relativePath: {eq: "MMA/MMA-Jugend-ground-and-pound.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  mmaKids: file(relativePath: {eq: "MMA/MMA-Kickboxen-Kinder-Muenchen.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust1: file(
    relativePath: {eq: "Kampfsportschule-des-Jahres-Team-Laurien-Alex.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust2: file(relativePath: {eq: "IBJJF-Zerifikat-TLA-BJJ.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust3: file(relativePath: {eq: "Deutsche-Meister-BJJ-IBJJF-Kickboxen.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust5: file(
    relativePath: {eq: "Yogalehrerzertifikat-in-indien-von-Lalit-Kumar-Himalaya-Yoga-Valley.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust4: file(relativePath: {eq: "Reviews-Team-Laurien-Alex.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  trust6: file(
    relativePath: {eq: "Laurien-and-alex-with-Cadu-Francis-purple-belt-graduation.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  step1: file(
    relativePath: {eq: "Persoenliche-Unterstuetzung-beim-kostenlosen-Probetraining-TLA-Muenchen.JPG"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  step2: file(relativePath: {eq: "Spass-beim-Training-im-Team-Muenchen.JPG"}) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  step3: file(
    relativePath: {eq: "Fitness-verbessern-team-laurien-alex-chaturanga-liegestuetz.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  magnet: file(relativePath: {eq: "Cover_Bist_du_gestresst_atemuebungen.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
}
`
export default MMA
