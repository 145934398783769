import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import styled from "styled-components"

import globals from "./globals"
import { H2, P } from "./styledcomponents"

const OctagonStyle = styled.div`
  width: 80vw;
  height: 80vw;
  overflow: hidden;
  filter: drop-shadow(2px 2px 4px #00000040);
  @media (min-width: ${globals.media.mobile}) {
    width: 40vw;
    height: 40vw;
  }
  @media (min-width: ${globals.media.tablet}) {
    width: 20vw;
    height: 20vw;
  }
`

const OctagonOuter = styled(OctagonStyle)`
  position: relative;
  transform: rotate(-45deg);
`

const OctagonImage = styled(GatsbyImage)`
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.5);
  }
`

const OctagonInner = styled(OctagonStyle)`
  transform: rotate(45deg);
  cursor: pointer;
  &:hover ${OctagonImage} {
    transform: scale(1.5);
  }
`

const Text = styled.div`
  position: absolute;
  top: 50%;
  top: ${(props) => props.top};
  left: 0;
  right: 0;
  background-color: #00000080;
  backdrop-filter: blur(3px);
  margin: 0;
  padding: 0;
`

const Title = styled(H2)``

const Description = styled(P)`
  ${(props) => (props.showDesc ? "display: none" : undefined)};
`

function Octagon({ image, title, description }) {
  const [showDesc, setShowDesc] = useState(false)

  function toggle() {
    setShowDesc(!showDesc)
  }

  return (
    <OctagonOuter>
      <OctagonInner onClick={toggle}>
        <OctagonImage image={image} alt={title} />
        <Text top={showDesc ? "25%" : "50%"}>
          <Title center color={globals.color.main}>
            {title}
          </Title>
          {showDesc ? (
            <Description center color="white">
              {description}
            </Description>
          ) : undefined}
        </Text>
      </OctagonInner>
    </OctagonOuter>
  )
}

export default Octagon
